import * as React from "react";
import "./header.scss";
import logo from "../../assets/icons_site/logo.svg";
import firebase from "firebase/app";
import BigArrow from "../big-arrow/big-arrow";
import LangSwitcher from "../lang-switcher/lang-switcher";

class Header extends React.Component {
  provider = new firebase.auth.GoogleAuthProvider();
  checkAdmin () {
    return document.URL.split("/").find((item)=> item === 'admin');
  }
  signIn() {
    firebase.auth()
    .signInWithPopup(this.provider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      //var credential = result.credential;
  
      // This gives you a Google Access Token. You can use it to access the Google API.
      // var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      this.props.onSelectUser(user);            


    }).catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      console.log(errorCode,errorMessage, email, credential);
      
      // ...
    });
  }


  render() {
    return (
      <header>
        <div className="header-wrapper">
          {this.checkAdmin() ? (
            <div>
              <button
                onClick={() => {
                  this.signIn()
                }}
              >
                Sign In with Google
              </button>
              <button
                onClick={() => {
                  firebase.default.auth().signOut();
                }}
              >
                Sign Out
              </button>
            </div>
          ) : (
            ""
          )}

          <div className="main-header">
            <img
              className="logo"
              src={logo}
              height="65px"
              width="380px"
              alt="лого обмін валют"
            ></img>
            {/* <LangSwitcher className="lang-position"></LangSwitcher> */}
          </div>
        </div>
        <BigArrow
          translateY="translateY(-2px)"
          className="line-left"
        ></BigArrow>
      </header>
    );
  }
}

export default Header;
