import React from "react";
import "./row.currency.scss";
import firebase from "firebase/app";

class RowCurrency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ifEdit: false,
      icon: "usd",
      code: "",
      name: "",
      buy: 0,
      buyUp: 0,
      sale: 0,
      saleUp: 0,
      position: 1,
      updateSale: new Date(),
      updateBuy: new Date()
    };


  }
detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

componentDidMount() {
  // const viberLink = document.getElementById('viberLink')
  // if(this.detectMob()) {
  //   viberLink.href = 'viber://add?number=%2B380971138115';
  //   } else {
  //   viberLink.href = 'viber://chat?number=%2B380971138115';
  //   }
}

  getCurrentIcons(name) {
    try {
      return require(`../../assets/icons/${name.toLowerCase()}.svg`).default;
    } catch (error) {
      return require(`../../assets/icons/usd.svg`).default;
    }
  }

  checkUpOrDown(up) {
    if (up > 0) {
      return "up";
    } else if (up < 0) {
      return "down";
    } else {
      return "";
    }
  }

  editItem() {
    if (this.state.ifEdit) {
      this.onSubmit();
    }

    this.setState({
      ...this.state,
      ...this.props.data,
      ifEdit: !this.state.ifEdit,
    });
  }
  checkAdminLogIn() {
    return (
      this.props &&
      this.props.user &&
      (this.props.user.email === "smartlogic.group@gmail.com" || this.props.user.uid ===  "1dWB6DKD3gcWzI3sOlpFxXo205e2")
    );
  }

  changeInput = (e) => {
    this.setState((state) => {
      return {
        ...state,
        [e.target.name]: e.target.value,
      };
    });
    setTimeout(() => {
      this.setState((state) => {
        return {
          ...state,
          buyUp: Number(this.state.buy) - Number(this.props.data.buy),
          saleUp: Number(this.state.sale) - Number(this.props.data.sale),
        };
      });
    }, 50);
  };

  onSubmit = () => {
    if (!this.state.code.trim() || !this.state.name.trim()) {
      return;
    }
   if(this.props.data.sale !== this.state.sale) {
      firebase
    .database()
    .ref(`currencies/${this.props.uid}`)
    .update({
      icon: this.state.icon,
      code: this.state.code,
      name: this.state.name,
      sale: this.state.sale,
      saleUp: this.state.saleUp,
      position: this.state.position,
      updateSale: new Date().toISOString()
    })
    .then((_) => {
      this.setState({
        icon: "usd",
        code: "",
        name: "",
        buy: "0",
        buyUp: "0",
        sale: "0",
        saleUp: "0",
        position: 1,
        updateBuy: new Date(),
        updateSale: new Date()
      });
    });
   } else if(this.props.data.buy === this.state.buy) {
    firebase
    .database()
    .ref(`currencies/${this.props.uid}`)
    .update({...this.state})
    .then((_) => {
      this.setState({
        icon: "usd",
        code: "",
        name: "",
        buy: "0",
        buyUp: "0",
        sale: "0",
        saleUp: "0",
        position: 1,
        updateBuy: new Date(),
        updateSale: new Date()
      });
    });
   }
   if(this.props.data.buy !== this.state.buy) {
    firebase
    .database()
    .ref(`currencies/${this.props.uid}`)
    .update({
      icon: this.state.icon,
      code: this.state.code,
      name: this.state.name,
      buy: this.state.buy,
      buyUp: this.state.buyUp,
      position: this.state.position,
      updateBuy: new Date().toISOString()
    })
    .then((_) => {
      this.setState({
        icon: "usd",
        code: "",
        name: "",
        buy: "0",
        buyUp: "0",
        sale: "0",
        saleUp: "0",
        position: 1,
        updateBuy: new Date(),
        updateSale: new Date()
      });
    });
   } else {
    firebase
    .database()
    .ref(`currencies/${this.props.uid}`)
    .update({...this.state})
    .then((_) => {
      this.setState({
        icon: "usd",
        code: "",
        name: "",
        buy: "0",
        buyUp: "0",
        sale: "0",
        saleUp: "0",
        position: 1,
        updateBuy: new Date(),
        updateSale: new Date()
      });
    });
   }

  };

  getDate(date) {
    if(new Date(date).toLocaleDateString() !== new Date().toLocaleDateString()) {
      return date ? new Date(date).toLocaleDateString().substr(0, 5) : new Date().toLocaleDateString().substr(0, 5)
    } else {
      return date ? new Date(date).toLocaleTimeString().substr(0, 5) : new Date().toLocaleTimeString().substr(0, 5)

    }
  }

  render() {
    return (
      <div className="row-currency">
        <div className="name-currency">
          {this.props.ifAdmin ? (
            <div className="controlsUpDown">
              <button
                className="upBtn"
                onClick={() => {
                  firebase
                    .database()
                    .ref(`currencies/${this.props.uid}`)
                    .update({ position: this.props.data.position + 1 });
                }}
              ></button>
              <button
                className="downBtn"
                onClick={() => {
                  firebase
                    .database()
                    .ref(`currencies/${this.props.uid}`)
                    .update({ position: this.props.data.position - 1 });
                }}
              ></button>
            </div>
          ) : (
            ""
          )}
          <img
            src={this.getCurrentIcons(this.props.data.code)}
            width="84px"
            height="55px"
            alt={this.props.data.code}
          ></img>
          <div className="info">
            {!this.state.ifEdit ? (
              <span className="main">{this.props.data.code}</span>
            ) : (
              <input
                placeholder="code"
                name="code"
                value={this.state.code}
                onChange={this.changeInput}
              ></input>
            )}
            {!this.state.ifEdit ? (
              <span className="sub country">{this.props.data.name}</span>
            ) : (
              <input
                placeholder="name"
                name="name"
                value={this.state.name}
                onChange={this.changeInput}
              ></input>
            )}
          </div>
        </div>
        <div className="prices">
          <div className="buy-currency">
            <div className="info">
              {!this.state.ifEdit ? (
                <span className="main">{this.props.data.buy}</span>
              ) : (
                <input
                  type="number"
                  min="0.01"
                  step="0.01"
                  placeholder="buy"
                  name="buy"
                  value={this.state.buy}
                  onChange={this.changeInput}
                ></input>
              )}
              <span
                className={"sub " + this.checkUpOrDown(+this.props.data.buyUp)}
              >
                {Math.abs(this.props.data.buyUp) !== 0
                  ? Math.abs(this.props.data.buyUp).toFixed(2)
                  : "—"} /
                  {this.props.data.updateBuy ? ' ' +this.getDate(this.props.data.updateBuy) : ' ' +this.getDate(this.props.data.updateBuy)}
              </span>
            </div>
          </div>
          <div className="sale-currency">
            <div className="info">
              {!this.state.ifEdit ? (
                <span className="main">{this.props.data.sale}</span>
              ) : (
                <input
                  type="number"
                  min="0.01"
                  step="0.01"
                  placeholder="sale"
                  name="sale"
                  value={this.state.sale}
                  onChange={this.changeInput}
                ></input>
              )}
              <span
                className={"sub " + this.checkUpOrDown(+this.props.data.saleUp)}
              >
                {Math.abs(this.props.data.saleUp) !== 0
                  ? Math.abs(this.props.data.saleUp).toFixed(2)
                  : "—"} /
                  {this.props.data.updateSale ? ' ' + this.getDate(this.props.data.updateSale) :' ' + this.getDate(this.props.data.updateSale)}
              </span>
            </div>
          </div>
          {this.checkAdminLogIn() ? (
            <div className="controlls">
              <button
                className="deleteBtn"
                onClick={() => {
                  firebase
                    .database()
                    .ref(`currencies/${this.props.uid}`)
                    .remove();
                }}
              ></button>
              <button
                className="editBtn"
                onClick={() => {
                  this.editItem();
                }}
              ></button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default RowCurrency;
