import React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "@firebase/storage";
import {
  FirebaseAuthProvider
} from "@react-firebase/auth";
import Table from "./show-currencies/table.currency";

import { firebaseConfig } from "./auth/firebase.config";
import "./App.scss";
import Header from "./layout/header/header";
import Recommendation from "./layout/recommendation/recomendation";
import Contacts from "./layout/contacts/contacts";
import Footer from "./layout/footer/footer";

class App extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      user: null,
      forex: []
    }

  }

  handleAuthUser = (user) => {
    this.setState({ user });
  }

  currencyPair = {
    EUR: {
      pair: ['USD', 'CHF', 'GBP', 'PLN', 'UAH'],
      key: 'EUR'
    },
    GBP: {
      pair: ['USD', 'CHF', 'UAH'],
      key: 'GBP'
    },
    USD: {
      pair: ['EUR', 'CHF', 'GBP', 'PLN', 'UAH', 'CAD', 'JPY', 'HKD', 'SGD', 'RUB'],
      key: 'USD'
    }
  }

  componentWillMount(){
    this.loadData();

    // EUR to usd chf gbp pln uah 
    // gbp to usd chf uah 
    // usd to cad chf jpy pln hkd sgd rub uah
  
  }


  loadData() {
    this.setState({...this.setState, forex: []}, ()=> {


    fetch(`https://api.exchangerate.host/latest/?symbols=${this.currencyPair.USD.pair}&base=${this.currencyPair.USD.key}`)
    .then(response => response.json())
    .then(forex => {
      let forexData = [];
      
      Object.keys(forex.rates).map(key => {
      forexData.push({from: forex.base, to: key, rate: forex.rates[key]})
      });      
      this.setState({...this.state, forex: [...this.state.forex, ...forexData]});
      
    })
    .catch(e => console.log(e));
  fetch(`https://api.exchangerate.host/latest/?symbols=${this.currencyPair.EUR.pair}&base=${this.currencyPair.EUR.key}`)
  .then(response => response.json())
  .then(forex => {
    let forexData = [];

    Object.keys(forex.rates).map(key => {
      forexData.push({from: forex.base, to: key, rate: forex.rates[key]})
    });      
    this.setState({...this.state, forex: [...this.state.forex, ...forexData]})
  })
  .catch(e => console.log(e));

  fetch(`https://api.exchangerate.host/latest/?symbols=${this.currencyPair.GBP.pair}&base=${this.currencyPair.GBP.key}`)
  .then(response => response.json())
  .then(forex => {
    let forexData = [];

    Object.keys(forex.rates).map(key => {
      forexData.push({from: forex.base, to: key, rate: forex.rates[key]})
    });      
    this.setState({...this.state, forex: [...this.state.forex, ...forexData]})
  })
  .catch(e => console.log(e));
});

  }

  render() {
    return (
      <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
        <div>
          <Header onSelectUser={this.handleAuthUser}></Header>
          <Table user={this.state.user} forex={this.state.forex}></Table>
          <Recommendation></Recommendation>
          <Contacts></Contacts>
          <Footer></Footer>
        </div>
      </FirebaseAuthProvider>
    )
  }
 
}

export default App;
