import * as React from "react";
import firebase from "firebase/app";
import "firebase/database";
import "./post.currency.scss";

class PostCurrency extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      icon: "usd",
      code: "",
      name: "",
      buy: 0,
      buyUp: 0,
      sale: 0,
      saleUp: 0,
      position: props.maxPoss,
    }
  }
  changeInput = e => {
    this.setState(state => {
      return {
        ...state,
        [e.target.name]: e.target.value
      }
    })
  }

  onSubmit = e => {
    e.preventDefault();
    if(!this.state.code.trim() || !this.state.name.trim()) {
      return;
    };

    firebase.database().ref(`currencies/`)
    .push({...this.state})
    .then(_ => {
      this.setState({
        icon: "usd",
        code: "",
        name: "",
        buy: "0",
        buyUp: "0",
        sale: "0",
        saleUp: "0",
        position: this.props.maxPoss,
      });
     });
  }
  render() {
    return (
      <div>
        <form className="inputs" onSubmit={this.onSubmit}>

          <input type="text" placeholder="code" name="code" value={this.state.code} onChange={this.changeInput}></input>
          <input placeholder="name" name="name" value={this.state.name} onChange={this.changeInput}></input>
          <input type="number" min="0.01" step="0.01" placeholder="buy" name="buy" value={this.state.buy} onChange={this.changeInput}></input>
          <input type="number" min="0.01" step="0.01" placeholder="sale" name="sale" value={this.state.sale} onChange={this.changeInput}></input>

          <button type="submit">
            ADD
          </button>
        </form>

      </div>
    );
  }
}

export default PostCurrency;