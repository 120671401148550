import React from "react";
import "./footer.scss";
import footerLogo from "../../assets/footer/footerLogo.svg";
import footerArrow from "../../assets/footer/footerArrow.svg";
import viber from "../../assets/social-icon/viber.svg"
import facebook from "../../assets/social-icon/facebook.svg"
import telegram from "../../assets/social-icon/telegram.svg"
import instagram from "../../assets/social-icon/instagram.svg"


class Footer extends React.Component {
  state = {};

  scrollHome() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

  return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
  });
}

componentDidMount() {
  // const viberLink = document.getElementById('viberLinkF')
  // if(this.detectMob()) {
  //   viberLink.href = 'viber://add?number=%2B380971138115';
  //   } else {
  //   viberLink.href = 'viber://chat?number=%2B380971138115';
  //   }
}
  render() {
    return (
      <div className="footer-wrap">
        <div className="footer">
          <div className="soc-icons">
          {/* <a id="viberLinkF" href="viber://add?number=%2B380971138115"><img src={viber} alt="img"/></a> */}
                  <a href="https://t.me/ObminValut_BigBen" target="_blank"><img src={telegram} alt="img"/></a>
                  {/* <a href="https://www.facebook.com/tcbigben/" target="_blank"><img src={facebook} alt="img"/></a> */}
                  <a href="https://www.instagram.com/obminnuk.big_ben/" target="_blank"><img src={instagram} alt="img"/></a>

          </div>
          <div className="logo-footer-wrap">
            <a className="footerLogo" href="https://smartlogic.com.ua/" target="_blank">
              {" "}
              <img src={footerLogo} alt="img" />
            </a>
            <img
              onClick={this.scrollHome}
              className="foterArrow"
              src={footerArrow}
              alt="img"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
