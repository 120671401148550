import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import { FirebaseDatabaseProvider } from "@react-firebase/database";
import { firebaseConfig } from "../auth/firebase.config";
import "./table.currency.scss";

import RowCurrency from "./row-currency/row.currency";
import PostCurrency from "../post-currency/post.currency";
import BigArrow from "../layout/big-arrow/big-arrow";
import Chart from "chart.js";
import RowForex from "./row-forex-pair/row.forex";




class Table extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      maxPoss: 1,
      limit: 10,
      visibleOther: true,
      currentPairFrom: 'USD',
      currentPairTo: 'EUR',
    };
  }

  newState;
  chartRef = React.createRef();


  async componentDidMount() {
    this.printForexGraph();
    const commentsRef = firebase
      .database()
      .ref("currencies/")
      .orderByChild("position")
      .limitToLast(this.state.limit).on("value", (snapshot) => {
      this.newState = [];
      snapshot.forEach((symptomSnapshot) => {
        let symptom = symptomSnapshot.val();
        this.newState.push({ ...symptom, key: symptomSnapshot.key });
      });
      this.newState.forEach((item) => {
        if (this.state.maxPoss < item.position) {
          this.setState((state) => {
            return {
              ...state,
              maxPoss: item.position,
            };
          });
        }
      });

      this.setState({
        isLoaded: true,
        items: this.newState.reverse(),
      });
      this.newState = [];
    });
// "Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"
    let data = {
      //Bring in data
      labels: [],
      datasets: [
        {label: "", borderWidth: '1', backgroundColor: '#00000000',pointStyle: "rect", borderColor: "#c74d39", pointBackgroundColor: '#c74d39', pointBorderColor: '#c74d39',
         data: []},
        {label: "", borderWidth: '1', backgroundColor: '#00000000', pointStyle: "rect", borderColor: "#58c439", pointBackgroundColor: '#58c439', pointBorderColor: '#58c439',
         data: []}
      ]
    };


    let dateUsd = '';
    let todayUsd = new Date();
    let dateEur = '';
    let todayEur = new Date();
    let loadedDataUsd = false;
    let loadedDataEur = false;

    // завантаження графіку по євро
    dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
    todayEur.setMonth(todayEur.getMonth() - 1);
    fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=EUR&date=${dateEur}&json`)
      .then(response => response.json())
      // eslint-disable-next-line no-loop-func
      .then(result => {
        data.datasets[1].data.push(result[0].rate.toFixed(2));
        dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
        todayEur.setMonth(todayEur.getMonth() - 1);

        fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=EUR&date=${dateEur}&json`)
          .then(response => response.json())
          // eslint-disable-next-line no-loop-func
          .then(result => {

            data.datasets[1].data.push(result[0].rate.toFixed(2));


            dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
            todayEur.setMonth(todayEur.getMonth() - 1);

            fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=EUR&date=${dateEur}&json`)
              .then(response => response.json())
              // eslint-disable-next-line no-loop-func
              .then(result => {

                data.datasets[1].data.push(result[0].rate.toFixed(2));


                dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
                todayEur.setMonth(todayEur.getMonth() - 1);

                fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=EUR&date=${dateEur}&json`)
                  .then(response => response.json())
                  // eslint-disable-next-line no-loop-func
                  .then(result => {

                    data.datasets[1].data.push(result[0].rate.toFixed(2));


                    dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
                    todayEur.setMonth(todayEur.getMonth() - 1);

                    fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=EUR&date=${dateEur}&json`)
                      .then(response => response.json())
                      // eslint-disable-next-line no-loop-func
                      .then(result => {

                        data.datasets[1].data.push(result[0].rate.toFixed(2));


                        dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
                        todayEur.setMonth(todayEur.getMonth() - 1);

                        fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=EUR&date=${dateEur}&json`)
                          .then(response => response.json())
                          // eslint-disable-next-line no-loop-func
                          .then(result => {

                            data.datasets[1].data.push(result[0].rate.toFixed(2));


                            dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
                            todayEur.setMonth(todayEur.getMonth() - 1);

                            fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=EUR&date=${dateEur}&json`)
                              .then(response => response.json())
                              // eslint-disable-next-line no-loop-func
                              .then(result => {

                                data.datasets[1].data.push(result[0].rate.toFixed(2));


                                dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
                                todayEur.setMonth(todayEur.getMonth() - 1);

                                fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=EUR&date=${dateEur}&json`)
                                  .then(response => response.json())
                                  // eslint-disable-next-line no-loop-func
                                  .then(result => {

                                    data.datasets[1].data.push(result[0].rate.toFixed(2));


                                    dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
                                    todayEur.setMonth(todayEur.getMonth() - 1);

                                    fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=EUR&date=${dateEur}&json`)
                                      .then(response => response.json())
                                      // eslint-disable-next-line no-loop-func
                                      .then(result => {

                                        data.datasets[1].data.push(result[0].rate.toFixed(2));


                                        dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
                                        todayEur.setMonth(todayEur.getMonth() - 1);

                                        fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=EUR&date=${dateEur}&json`)
                                          .then(response => response.json())
                                          // eslint-disable-next-line no-loop-func
                                          .then(result => {

                                            data.datasets[1].data.push(result[0].rate.toFixed(2));


                                            dateEur = `${todayEur.getFullYear()}${todayEur.getMonth() + 1 < 10 ? '0' + (todayEur.getMonth() + 1) : todayEur.getMonth() + 1}01`
                                            todayEur.setMonth(todayEur.getMonth() - 1);
                                            loadedDataEur = true;
                                          })
                                          .catch(e => console.log(e));


                                      })
                                      .catch(e => console.log(e));

                                  })
                                  .catch(e => console.log(e));


                              })
                              .catch(e => console.log(e));


                          })
                          .catch(e => console.log(e));


                      })
                      .catch(e => console.log(e));

                  })
                  .catch(e => console.log(e));

              })
              .catch(e => console.log(e));

          })
          .catch(e => console.log(e));

      })
      .catch(e => console.log(e));
   
     //  завантаження графіку по долару
    dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
    todayUsd.setMonth(todayUsd.getMonth() - 1);
    fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${dateUsd}&json`)
      .then(response => response.json())
      // eslint-disable-next-line no-loop-func
      .then(result => {

        data.labels.push(result[0].exchangedate);
        data.datasets[0].data.push(result[0].rate.toFixed(2));

        dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
        todayUsd.setMonth(todayUsd.getMonth() - 1);

        fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${dateUsd}&json`)
          .then(response => response.json())
          // eslint-disable-next-line no-loop-func
          .then(result => {

            data.labels.push(result[0].exchangedate);
            data.datasets[0].data.push(result[0].rate.toFixed(2));

            dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
            todayUsd.setMonth(todayUsd.getMonth() - 1);

            fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${dateUsd}&json`)
              .then(response => response.json())
              // eslint-disable-next-line no-loop-func
              .then(result => {

                data.labels.push(result[0].exchangedate);
                data.datasets[0].data.push(result[0].rate.toFixed(2));

                dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
                todayUsd.setMonth(todayUsd.getMonth() - 1);

                fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${dateUsd}&json`)
                  .then(response => response.json())
                  // eslint-disable-next-line no-loop-func
                  .then(result => {

                    data.labels.push(result[0].exchangedate);
                    data.datasets[0].data.push(result[0].rate.toFixed(2));

                    dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
                    todayUsd.setMonth(todayUsd.getMonth() - 1);

                    fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${dateUsd}&json`)
                      .then(response => response.json())
                      // eslint-disable-next-line no-loop-func
                      .then(result => {

                        data.labels.push(result[0].exchangedate);
                        data.datasets[0].data.push(result[0].rate.toFixed(2));

                        dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
                        todayUsd.setMonth(todayUsd.getMonth() - 1);

                        fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${dateUsd}&json`)
                          .then(response => response.json())
                          // eslint-disable-next-line no-loop-func
                          .then(result => {

                            data.labels.push(result[0].exchangedate);
                            data.datasets[0].data.push(result[0].rate.toFixed(2));

                            dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
                            todayUsd.setMonth(todayUsd.getMonth() - 1);

                            fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${dateUsd}&json`)
                              .then(response => response.json())
                              // eslint-disable-next-line no-loop-func
                              .then(result => {

                                data.labels.push(result[0].exchangedate);
                                data.datasets[0].data.push(result[0].rate.toFixed(2));

                                dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
                                todayUsd.setMonth(todayUsd.getMonth() - 1);

                                fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${dateUsd}&json`)
                                  .then(response => response.json())
                                  // eslint-disable-next-line no-loop-func
                                  .then(result => {

                                    data.labels.push(result[0].exchangedate);
                                    data.datasets[0].data.push(result[0].rate.toFixed(2));

                                    dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
                                    todayUsd.setMonth(todayUsd.getMonth() - 1);

                                    fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${dateUsd}&json`)
                                      .then(response => response.json())
                                      // eslint-disable-next-line no-loop-func
                                      .then(result => {

                                        data.labels.push(result[0].exchangedate);
                                        data.datasets[0].data.push(result[0].rate.toFixed(2));

                                        dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
                                        todayUsd.setMonth(todayUsd.getMonth() - 1);

                                        fetch(`https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?valcode=USD&date=${dateUsd}&json`)
                                          .then(response => response.json())
                                          // eslint-disable-next-line no-loop-func
                                          .then(result => {

                                            data.labels.push(result[0].exchangedate);
                                            data.datasets[0].data.push(result[0].rate.toFixed(2));

                                            dateUsd = `${todayUsd.getFullYear()}${todayUsd.getMonth() + 1 < 10 ? '0' + (todayUsd.getMonth() + 1) : todayUsd.getMonth() + 1}01`
                                            todayUsd.setMonth(todayUsd.getMonth() - 1);
                                            loadedDataUsd = true;
                                          })
                                          .catch(e => console.log(e));


                                      })
                                      .catch(e => console.log(e));

                                  })
                                  .catch(e => console.log(e));


                              })
                              .catch(e => console.log(e));


                          })
                          .catch(e => console.log(e));


                      })
                      .catch(e => console.log(e));

                  })
                  .catch(e => console.log(e));

              })
              .catch(e => console.log(e));

          })
          .catch(e => console.log(e));

      })
      .catch(e => console.log(e));


   
   const interval =  setInterval(() => {
      if(loadedDataUsd && loadedDataEur) {
        clearInterval(interval);
        data.labels.reverse();
        data.datasets[0].data.reverse();
        data.datasets[1].data.reverse();
        let options= {
          title: {
            display: false
          },
        
          legend: {
            labels: {
              boxWidth: 0,
              fontColor: 'rgba(255, 255, 255)',
              fontSize: 16,
              padding: 16
            },
          },
          scales: {
            xAxes: [{
              
              gridLines: {
                color: '#5a5a5a',
                
              },
              ticks: {
                padding: 5,
                fontSize: 7,
                fontColor: "#9e9e9e",
                fontFamily: "Montserrat Light"
        
              }
            }],
            yAxes: [{
              gridLines: {
                color: '#c74d39',
                zeroLineWidth: 20
              },
              ticks: {
                padding: 5,
                lineHeight: 4.5,
                stepSize: 0.1,
              }
            }]
          },
          elements: {
            line: {
                tension: 0
            }
        },
        customTooltips: false,
        
        
        }
            // const myChartRef = this.chartRef.current.getContext("2d");
            var ctx1 = document.getElementById("nbuGraph").getContext("2d");
                new Chart(ctx1, {
                    type: "line",
                    data,
                    options
                });       
      }
    }, 100);

  }


  
  checkAdminLogIn() {                
    return (
      this.props &&
      this.props.user && 
      (this.props.user.email === "smartlogic.group@gmail.com" || this.props.user.uid ===  "1dWB6DKD3gcWzI3sOlpFxXo205e2")
    );
  }
  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  selectPair = (pair) => { 
    this.setState({...this.state, currentPairFrom: pair.from,currentPairTo: pair.to}, ()=> {      
      this.printForexGraph();
    })
  }

  printForexGraph() {
    let data = {
      //Bring in data
      labels: [],
      datasets: [
        {label: "", borderWidth: '1', backgroundColor: '#00000000',pointStyle: "rect", borderColor: "#c74d39", pointBackgroundColor: '#c74d39', pointBorderColor: '#c74d39',
         data: []},
        {label: "", borderWidth: '1', backgroundColor: '#00000000', pointStyle: "rect", borderColor: "#58c439", pointBackgroundColor: '#58c439', pointBorderColor: '#58c439',
         data: []}
      ]
    };

    let options= {
      title: {
        display: false
      },
    
      legend: {
        labels: {
          boxWidth: 0,
          fontColor: 'rgba(255, 255, 255)',
          fontSize: 16,
          padding: 16
        },
      },
      scales: {
        xAxes: [{
          
          gridLines: {
            color: '#5a5a5a',
            
          },
          ticks: {
            padding: 5,
            fontSize: 7,
            fontColor: "#9e9e9e",
            fontFamily: "Montserrat Light"
    
          }
        }],
        yAxes: [{
          gridLines: {
            color: '#c74d39',
            zeroLineWidth: 0
          },
          ticks: {
            padding: 5,
            lineHeight: 3.5,
            stepSize: 0.0001,
          }
        }]
      },
      elements: {
        line: {
            tension: 0
        }
    },
    customTooltips: false,
    
    
    }
   // FOR GRAPH
      let fDate = new Date();
      fDate.setDate(fDate.getDate() - 8);
 
      let sDate = new Date();
      sDate.setDate(sDate.getDate() - 7);
     fetch(`https://api.exchangerate.host/fluctuation?start_date=${fDate.toISOString().split('T')[0]}&end_date=${sDate.toISOString().split('T')[0]}&symbols=${this.state.currentPairTo}&base=${this.state.currentPairFrom}`)
     .then(response => response.json())
     .then(forex => {
      data.labels.push(forex.end_date);
      data.datasets[0].data.push(forex.rates[this.state.currentPairTo].start_rate);
      data.datasets[1].data.push(forex.rates[this.state.currentPairTo].end_rate);
      fDate.setDate(fDate.getDate() + 1);
      sDate.setDate(sDate.getDate() + 1);
      fetch(`https://api.exchangerate.host/fluctuation?start_date=${fDate.toISOString().split('T')[0]}&end_date=${sDate.toISOString().split('T')[0]}&symbols=${this.state.currentPairTo}&base=${this.state.currentPairFrom}`)
      .then(response => response.json())
      .then(forex => {
       data.labels.push(forex.end_date);
       data.datasets[0].data.push(forex.rates[this.state.currentPairTo].start_rate);
       data.datasets[1].data.push(forex.rates[this.state.currentPairTo].end_rate);
 
       fDate.setDate(fDate.getDate() + 1);
      sDate.setDate(sDate.getDate() + 1);
      fetch(`https://api.exchangerate.host/fluctuation?start_date=${fDate.toISOString().split('T')[0]}&end_date=${sDate.toISOString().split('T')[0]}&symbols=${this.state.currentPairTo}&base=${this.state.currentPairFrom}`)
      .then(response => response.json())
      .then(forex => {
       data.labels.push(forex.end_date);
       data.datasets[0].data.push(forex.rates[this.state.currentPairTo].start_rate);
       data.datasets[1].data.push(forex.rates[this.state.currentPairTo].end_rate);
 
       fDate.setDate(fDate.getDate() + 1);
      sDate.setDate(sDate.getDate() + 1);
      fetch(`https://api.exchangerate.host/fluctuation?start_date=${fDate.toISOString().split('T')[0]}&end_date=${sDate.toISOString().split('T')[0]}&symbols=${this.state.currentPairTo}&base=${this.state.currentPairFrom}`)
      .then(response => response.json())
      .then(forex => {
       data.labels.push(forex.end_date);
       data.datasets[0].data.push(forex.rates[this.state.currentPairTo].start_rate);
       data.datasets[1].data.push(forex.rates[this.state.currentPairTo].end_rate);
 
       fDate.setDate(fDate.getDate() + 1);
      sDate.setDate(sDate.getDate() + 1);
      fetch(`https://api.exchangerate.host/fluctuation?start_date=${fDate.toISOString().split('T')[0]}&end_date=${sDate.toISOString().split('T')[0]}&symbols=${this.state.currentPairTo}&base=${this.state.currentPairFrom}`)
      .then(response => response.json())
      .then(forex => {
       data.labels.push(forex.end_date);
       data.datasets[0].data.push(forex.rates[this.state.currentPairTo].start_rate);
       data.datasets[1].data.push(forex.rates[this.state.currentPairTo].end_rate);
 
       fDate.setDate(fDate.getDate() + 1);
      sDate.setDate(sDate.getDate() + 1);
      fetch(`https://api.exchangerate.host/fluctuation?start_date=${fDate.toISOString().split('T')[0]}&end_date=${sDate.toISOString().split('T')[0]}&symbols=${this.state.currentPairTo}&base=${this.state.currentPairFrom}`)
      .then(response => response.json())
      .then(forex => {
       data.labels.push(forex.end_date);
       data.datasets[0].data.push(forex.rates[this.state.currentPairTo].start_rate);
       data.datasets[1].data.push(forex.rates[this.state.currentPairTo].end_rate);
 
       fDate.setDate(fDate.getDate() + 1);
      sDate.setDate(sDate.getDate() + 1);
      fetch(`https://api.exchangerate.host/fluctuation?start_date=${fDate.toISOString().split('T')[0]}&end_date=${sDate.toISOString().split('T')[0]}&symbols=${this.state.currentPairTo}&base=${this.state.currentPairFrom}`)
      .then(response => response.json())
      .then(forex => {
       data.labels.push(forex.end_date);
       data.datasets[0].data.push(forex.rates[this.state.currentPairTo].start_rate);
       data.datasets[1].data.push(forex.rates[this.state.currentPairTo].end_rate);
 
       fDate.setDate(fDate.getDate() + 1);
      sDate.setDate(sDate.getDate() + 1);
      fetch(`https://api.exchangerate.host/fluctuation?start_date=${fDate.toISOString().split('T')[0]}&end_date=${sDate.toISOString().split('T')[0]}&symbols=${this.state.currentPairTo}&base=${this.state.currentPairFrom}`)
      .then(response => response.json())
      .then(forex => {
       data.labels.push(forex.end_date);
       data.datasets[0].data.push(forex.rates[this.state.currentPairTo].start_rate);
       data.datasets[1].data.push(forex.rates[this.state.currentPairTo].end_rate);
 
       this.printGraphForex(data,options)


      })
      .catch(e => console.log(e));

      })
      .catch(e => console.log(e));

      })
      .catch(e => console.log(e));

      })
      .catch(e => console.log(e));

      })
      .catch(e => console.log(e));

      })
      .catch(e => console.log(e));

      })
      .catch(e => console.log(e));
     })
     .catch(e => console.log(e));



   
  
  }

    printGraphForex(data,options) {
      var ctx1 = document.getElementById("forexGraph").getContext("2d");
      new Chart(ctx1, {
        type: "line",
        data,
        options
      });
    }

  render() {

    return (
      <div>
        <div className="table-currency">
          <FirebaseDatabaseProvider firebase={firebase} {...firebaseConfig}>
            <React.Fragment>
              <div className="tables">
                <main>
                  <div className="main-table">
                    <header className="title">
                      <h2 className="name-title">
                        <span>КУРС ВАЛЮТ</span>
                        {this.checkAdminLogIn() ? (
                          <span>(ADMINISTRATOR)</span>
                        ) : (
                          ""
                        )}
                      </h2>
                      <h2 className="date-title">
                        <span>{new Date().toLocaleDateString()}</span>
                      </h2>
                    </header>
                    {this.checkAdminLogIn() && this.state.items ? (
                      <PostCurrency maxPoss={this.state.maxPoss}></PostCurrency>
                    ) : (
                      ""
                    )}

                    <div className="columns">
                      <h3 className="title-column name">
                        <span>Валюта</span>
                      </h3>
                      <h3 className="title-column buy-sale">
                        <span>Купівля</span>
                        <span>Продаж</span>
                      </h3>
                    </div>
                    <div className="rowWrap">
                    </div>

                    {this.state.items.length && this.state.items.map(item =>
                    <div key={item.key + 'main'}>
                      <RowCurrency key={item.key + Date().now} data={item} uid={item.key} user={this.props.user} ifAdmin={this.checkAdminLogIn()}></RowCurrency>
                    </div>
                  )}
                  </div>
                  {/* {this.state.visibleOther ? (
                    <button
                      className="otherCurrency"
                      onClick={() => {
                        this.setState((state) => ({
                          limit: state.limit + 100,
                          visibleOther: false,
                        }));
                        setTimeout(() => {
                          this.componentDidMount();
                        }, 10);
                      }}
                    >
                      ІНШІ КУРСИ ВАЛЮТ
                    </button>
                  ) : (
                    ""
                  )} */}
                </main>
                <div className="frame-wrap">
                  <div className="forexGraph">
                    <p className="title"> Forex  <span>{this.state.currentPairFrom} - {this.state.currentPairTo}</span></p>
                    <canvas
                      id="forexGraph"
                      ref={this.chartRef}
                    />
                   
                    <div className="label">
                      <div className="buy">
                       <span>початок дня</span>
                      </div>
                      <div className="sale">
                        <span>кінець дня</span>
                      </div>
                    </div>
                    <div className="list-forex">
                      {this.props.forex && this.props.forex.map(item => {
                        return <RowForex key={new Date().getTime() + item.from + item.to} item={item} changeSelectPair={this.selectPair} ></RowForex>
                      })}
                    </div>
                  </div>
                  <div className="nbuGraph">
                    <p className="title"> КУРС НБУ</p>
                    <canvas
                      id="nbuGraph"
                      ref={this.chartRef}
                    />
                    <div className="label">
                      <div className="buy">
                       <span> USD</span>
                      </div>
                      <div className="sale">
                        <span>EUR</span>
                      </div>
                    </div>
                   
                  </div>
                 
                </div>
              </div>
            </React.Fragment>
          </FirebaseDatabaseProvider>
        </div>
        <BigArrow className="line-right"></BigArrow>
      </div>
    );
  }
}

export default Table;
