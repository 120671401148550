import * as React from "react";
import "./big-arrow.scss"

class BigArrow extends React.Component {

    render() {
        return (
            <div className="arrow-bcg" style={{transform:this.props.translateY}}>
                <div className={this.props.className}></div>
            </div>
        );
    }
}

export default BigArrow;