import React from "react";
import './row.forex.scss';
class RowForex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {...props};
  }

componentDidMount(){
  // console.log(this.state);
}

changeSelectPair(item) {

  this.props.changeSelectPair(item);
}
  render() {
    return (
      <div className="forex">
        <div className="row-currency" onClick={()=> {this.changeSelectPair(this.state.item)}}>
          <div className="currency-name">
            <div className="fromF">{this.state.item.from}</div>
            <span>-</span>
            <div className="fromF">{this.state.item.to}</div> 
          </div>
          <div className="currency-rate">{this.state.item.rate}</div>
        </div>
      </div>
    );
  }
}

export default RowForex;
