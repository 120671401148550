import React from "react";
import "./lang-switcher.scss";

class LangSwitcher extends React.Component {
  state = {
    lang: [
      { id: 1, lang: "UA", status: "active" },
      { id: 2, lang: "RU", status: "disactiv" },
      { id: 3, lang: "EN", status: "disactiv" },
    ],
  };

  changeLang = (changeColor) => {
    changeColor--;
    for (let index = 0; index < 3; index++) {
      this.state.lang[index].status = "disactiv";
    }
    const langColor = this.state.lang[changeColor];
    langColor.status = "active";
    const langs = [...this.state.lang];
    langs[changeColor] = langColor;
    this.setState({
      lang: langs,
    });
  };

  render() {
    return (
      <div className={this.props.className}>
        <div className="language">
          {this.state.lang.map(({ id, lang, status }) => {
            return (
              <div onClick={() => this.changeLang(id)} key={id}>
                <span className={status + " " + "lang-name"}>{lang}</span>
                <div className="lang-line"></div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default LangSwitcher;
