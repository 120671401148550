import React from "react";
import "./recomendation.scss";
import smallArrow from "../../assets/big-arrow/smalArrow.svg";
import icon1 from "../../assets/recommendation/1.svg";
import icon2 from "../../assets/recommendation/2.svg";
import icon3 from "../../assets/recommendation/3.svg";

class Recommendation extends React.Component {
  state = {
    icons: [
      {
        text: "ЯКИЙ ЗАВТРА БУДЕ КУРС?",
        desc: "Це питання завжди загадка, навіть для найдосвідченіших фінансистів або банкірів, всі помиляються в даній галузі і на курс впливають 'ТОННИ' чинників.",
        icon: icon1,
        visibleDesc: false,
        id: 1,
      },
      {
        text: "КОЛИ КУПУВАТИ АБО ПРОДАВАТИ ВАЛЮТУ?",
        desc: "Порада дуже проста - приймайте рішення згідно Ваших потреб і Вашого досвіду.",
        icon: icon2,
        visibleDesc: false,
        id: 2,
      },
      {
        text: "ЩО ВПЛИВАЄНА КУРС ВАЛЮТ?",
        desc: "На курс впливає дуже багато факторів: політичні, економічні, спекулятивні, світові новини / події, навіть погода і т.д.",
        icon: icon3,
        visibleDesc: false,
        id: 3,
      },
    ],
  };

  openText(num) {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.setState({...this.state.icons[num-1].visibleDesc = !this.state.icons[num-1].visibleDesc,})
  }

  render() {
    return (
      <div className="reco-bcg">
        <div className="reco-title">
          <span className="title-text">НАШІ РЕКОМЕНДАЦІЇ</span>
          <img src={smallArrow} alt="img" />
        </div>
        <div className="icon-position">
          <div className="icon-wrap">
            {this.state.icons.map(({ icon, text, id, visibleDesc, desc }) => {
              return (
                <div key={id} className="icon-container">
                  <span className="icon-text" onClick={()=>{this.openText(id)}}>{text}</span>
                  <h3>
                    <img src={icon} className={visibleDesc ? 'hide' : 'show' } alt="img" />
                    <p className={visibleDesc ? 'show text-course' : 'hide text-course' }>
                      <span>{desc}</span>
                    </p>
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Recommendation;
