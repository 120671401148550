import React from "react";
import BigArrow from "../big-arrow/big-arrow";
import "./contacts.scss";
import smallArrow from "../../assets/big-arrow/smalArrow.svg";
import location from "../../assets/contacts/location.svg";
import phone from "../../assets/contacts/phone.svg";
import viber from "../../assets/social-icon/viber.svg"
import facebook from "../../assets/social-icon/facebook.svg"
import telegram from "../../assets/social-icon/telegram.svg"
import instagram from "../../assets/social-icon/instagram.svg"




class Contacts extends React.Component {
  state = {};
  render() {
    return (
      <div className="contacts-bcg">
        <BigArrow
          className="line-left"
          translateY="translateY(33px)"
        ></BigArrow>
        <div className="contact-position">
          <div className="contacts-title-wrap">
            <span className="contacts-title">КОНТАКТИ</span>
            <img src={smallArrow} alt="img" />
          </div>
          <div className="phones">
            <div className="phone-wrap">
              <img src={phone} alt="img" />
              <a href="tel:+380971138115">+38 097 11 38 115</a>
              <span></span>
              <p className="sub-phone">
                <span>Оптові курси за номером</span>
                <br></br>
              <a href="tel:+380997917775">+38 099 79 17 775</a>
              </p>

            </div>
            <div className="phone-wrap">
              <img src={location} alt="img" />
              <a href="https://goo.gl/maps/eQLrztMQHUNfvZHM6" target="_blank" rel="noreferrer">
              <span>ТЦ Біг Бен, вул. Шевченка, 9Г, м. Камінь-Каширський</span>
              </a>
            </div>
            <div className="phone-wrap">
              <img src={location} alt="img" />
              <a href="https://goo.gl/maps/V3mAQV3aoTSvNA2BA" target="_blank" rel="noreferrer">
              <span>майдан Незалежності, 3 м. Камінь-Каширський</span>
              </a>
              
              
            </div>
            <div className="social-wrap">
              <span>Приєднуйся до нас</span>
              <div className="soc-icons">
                  {/* <a id="viberLink" href="viber://add?number=%2B380971138115"><img src={viber} alt="img"/></a> */}
                  <a href="https://t.me/ObminValut_BigBen" target="_blank" rel="noreferrer"><img src={telegram} alt="img"/></a>
                  {/* <a href="https://www.facebook.com/tcbigben/" target="_blank" rel="noreferrer"><img src={facebook} alt="img"/></a> */}
                  <a href="https://www.instagram.com/obminnuk.big_ben/" target="_blank" rel="noreferrer"><img src={instagram} alt="img"/></a>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contacts;
